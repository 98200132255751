@charset "utf-8";

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";


// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: whitesmoke;

// Update Bulma's global variables
$font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $brown;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$family-primary: $font-family;


//using css for forms here : to be moved into application css when used and removed from botero


.notification.is-danger.is-light {
    padding: 0.7rem 0.5rem 0.5rem 0.7rem;
}
